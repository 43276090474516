@font-face {
  font-family: Brother;
  src: url(./assets/fonts/Brother/Brother-Black.ttf);
}
@font-face {
  font-family: BrotherBold;
  src: url(./assets/fonts/Brother/Brother-Bold.ttf);
}
* {
  font-family: BrotherBold, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
