.connectContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 13;
}

/* Header */

.connectHeader {
  width: 100%;
  height: 8rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 16vh;
}

.connectHeader .logo-left,
.connectHeader .connect-closeButton {
  width: 5rem;
  height: 5rem;
}

.header_right {
  display: flex;
  max-width: 17rem;
  justify-content: space-between;
  align-items: center;
}

.logo-left {
  width: 30rem !important;
}

.logo-right {
  width: 8rem !important;
  /* height: 8rem!important; */
}

.connect-closeButton {
  transform: scale(0.95);
  opacity: 0.9;
  transition: 0.15s;
  border-radius: 50%;
  cursor: pointer;
}

.heroBorder {
  position: absolute;
  right: 0;
  z-index: 5;
  top: 0;
  width: 5%;
  height: 100%;
  background: rgb(238, 35, 38);
  background: linear-gradient(
    90deg,
    rgba(238, 35, 38, 1) 49%,
    rgba(0, 0, 0, 1) 49%
  );
}

.connect-closeButton:hover {
  transform: scale(1.01);
  opacity: 1;
  /* box-shadow: 0px 4px 24px #ffe600; */
  box-shadow: 0px 0.2rem 1.2rem #d4a847;
}

/* Header-end */

/* MainBody */

.connectBody {
  padding: 4rem 5rem;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  max-height: 90vh;
  justify-content: center;
}

/*  */

/* Connect Card */

.connect-card {
  background: #fff;
  width: fit-content;
  max-width: 23rem;
  border-radius: 0.2rem;
  margin: 1rem;
  box-shadow: 0px 0px 1rem rgba(0, 0, 0, 0.596);
  transition: 0.25s;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}

.connect-card:hover {
  transform: scale(1.08);
  z-index: 5;
}

.connect-card-head {
  background: #d4a847;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  height: 10rem;
  padding: 1rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
}

.connect-card-head-image {
  width: 7rem;
  height: 7rem;
  min-width: 6.5rem;
  border-radius: 50%;
  border: 0.6px solid #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover !important;
}

.connect-card-head-details {
  max-width: 14.5rem;
  margin: 0 0.8rem;
}

.connect-card-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
  color: #fff;
}

.connect-card-title {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.connect-card-email {
  font-size: 1.15rem;
  font-weight: 500;
  color: #d4a847;
  margin-top: 0.8rem;
}

.connect-card-body {
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  height: fit-content;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.connect-card-body > q {
  /* position: absolute; */
  bottom: 36%;
}

.connect-card-button {
  background-color: #d4a847;
  color: #fff;
  padding: 0.8rem 2rem;
  border-radius: 0.25rem;
  /* position: absolute; */
  bottom: 8%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transform: scale(0.95);
  transition: 0.15s;
  cursor: pointer;
  margin-top: 1rem;
}

.connect-card-button:hover {
  background-color: #d4a847;
  color: #fff;
  box-shadow: 0px 0px 0.15rem #d4a847;
  opacity: 1;
  transform: scale(1);
  /* border: 0.05rem solid #EE2326; */
}

.connect-button {
  background-color: #d4a847;
  color: #000;
  text-align: center;
  margin-right: 1rem;
  padding: 1rem 3rem;
  border-radius: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.1rem;
  font-family: "Roboto";
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
  opacity: 1;
  transform: scale(0.95);
  transition: 0.15s;
  cursor: pointer;
}

.connect-button:hover {
  background-color: #1a1a24;
  color: #fff;
  box-shadow: 0px 0.2rem 1.2rem #d4a847;
  opacity: 1;
  transform: scale(1.04);
}
/* Connect Card - End */

@media only screen and (max-width: 991px) {
  .connectBody {
    max-height: 80vh;
  }
  .connectBody {
    padding: 2rem;
  }
  .connect-card {
    width: 100%;
  }
  .connectContainer {
    position: fixed;
  }
}
