.leaderboardContainer-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #272727;
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  padding: 2rem 0.5rem;
}

.leaderboardHeader {
  width: 100%;
  height: 8rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 10vh;
}

.leaderboardHeader div {
  width: 5rem;
  height: 5rem;
}

.logo-right {
  width: 8rem !important;
  height: 8rem !important;
}

.leaderboard-closeButton {
  transform: scale(0.95);
  opacity: 0.9;
  transition: 0.15s;
  border-radius: 50%;
  cursor: pointer;
}

.leaderboard-closeButton:hover {
  transform: scale(1);
  opacity: 1;
  box-shadow: 0px 4px 24px #0032AE;
}

/* Body */

.leaderboardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  padding-top: 0;
}

.leaderboardBody-title {
  font-size: 3.5rem;
  color: #0032AE;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

/* leaderboardBody-userblock */

.leaderboardBody-userblock {
  background: #000000;
  border: 0.15rem solid #0032AE;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  margin-bottom: 4rem;
}

.leaderboardBody-userblock div {
  margin: 0.5rem 2rem;
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.leaderboardBody-userblock div:last-child {
  margin-left: 5rem;
}

.leaderboardBody-userblock span {
  margin: 0rem 0.5rem;
}

.leaderboardBody-userblock .title {
  color: #0032AE;
  line-height: 2rem;
}

.leaderboardBody-userblock .value {
  color: #fff;
  font-size: 5rem;
}

/* ---- */

.scoreStar {
  width: 4rem;
  height: 4rem;
}

.leadeboard-tableheader th {
  padding: 0.8rem 0.5rem;
  color: #0032AE;
  background: #000000;
  border: 0.15rem solid #0032AE;
  border-radius: 0.8rem;
  text-transform: uppercase;
  font-size: 1.25rem;
  text-align: center;
}

.leaderboardBody table {
  width: 100%;
}

.leadeboard-tableData td {
  padding: 0.8rem 0.5rem;
  min-width: 3rem;
  max-width: 8rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25rem;
  text-align: center;
}

@media only screen and (max-width: 760px) {
  .leaderboardContainer-parent {
    overflow-y: auto;
    height: calc(100% - 4rem);
    padding-bottom: 2rem;
  }
  .leaderboardBody-dataContainer {
    width: 100%;
  }
  /* leaderboardBody-userblock */
  .leaderboardBody-userblock {
    border-radius: 0.8rem;
    padding: 1rem 0.5rem;
    margin-bottom: 2.5rem;
  }
  .leaderboardBody-userblock div {
    margin: 0.5rem;
    font-size: 1.25rem;
    font-weight: 500;
  }
  .leaderboardBody-userblock div:last-child {
    margin-left: 1rem;
  }
  .leaderboardBody-userblock span {
    margin: 0rem 0.5rem;
  }
  .leaderboardBody-userblock .title {
    color: #0032AE;
    line-height: 1.25rem;
  }
  .leaderboardBody-userblock .value {
    color: #fff;
    font-size: 1.4rem;
  }
  /* ---- */
  .tableDataContainer {
    height: 45vh;
  }
}

.leadeboard-tableData-active td {
  color: #0032AE !important;
}

@media screen and (orientation: landscape) {
  /* Your CSS Here*/
  .leaderboardContainer-parent {
    overflow-y: auto;
    height: calc(100%);
  }
}
.upper__part {
  display: flex;
  /* max-height: 20vh; */
}
.img__one {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.img__two {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.img__three {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.img__two img,
.img__one img,
.img__three img {
  max-width: 60%;
}
.scorecard__div {
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 5rem;
  padding-top: 3rem;
}
.your__rank {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rank__board {
  width: 60%;
  /* padding: 2rem; */
  display: flex;
  border: 0.5rem solid #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
.points {
  flex: 1;
  display: flex;
  /* padding: 1rem; */
  display: flex;
  justify-content: center;
}
.ranks {
  flex: 1;
  display: flex;
  /* padding: 1rem; */
  display: flex;
  justify-content: center;
}
.points h1 {
  font-size: 2rem;
  color: #fff;
  margin: 1rem;
}
.ranks h1 {
  font-size: 2rem;
  color: #fff;
  margin: 1rem;
}

.scoreboard {
  display: flex;
  justify-content: center;
}
.score__table {
  width: 60%;
  margin-top: 4rem;
}
.headings {
  display: flex;
  justify-content: space-around;
}
.headings div {
  /* flex: 1; */
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head p {
  padding: 0.5rem 1.5rem;
  border: 0.2rem solid #fff;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  margin-top: 0rem !important;
}
.data {
  display: flex;
}
.data div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  flex-direction: column;
  /* max-width: 25%; */
  min-width: fit-content;
  padding: 1rem;
}

.data div p {
  color: #fff;
  margin-top: 2rem;
  font-size: 1.5rem;
  text-align: center;
  word-break: break-all;
}
@media only screen and (max-width: 991px) {
  .data div p {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 991px) {
  .rank__board {
    width: 90%;
  }
  .score__table {
    width: 100%;
  }
  .scorecard__div {
    padding-top: 2rem;
  }
  .points h1 {
    font-size: 1.5rem;
  }
  .ranks h1 {
    font-size: 1.5rem;
  }
  .scores__h1 {
    font-size: 3rem !important;
  }
  .score__table {
    margin-top: 2rem;
  }
  .data div {
    padding: 0;
  }
}
