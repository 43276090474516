.lobbyTutContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 50;
}
.loading {
  height: 50%;
  width: 50%;
  position: absolute;
  z-index: -1;
}
.loading img {
  max-width: 50px;
}
.tutCardContainer {
  width: 25%;
  /* height: 50%; */
  background: #f5f4f4;
  border-radius: 0.2rem;
  height: auto;
  /* padding-bottom: 2rem; */
  /* color: #FFFFFF; */
}

.bg-red {
  background: #d4a847;
}

.pd-b-2 {
  padding-bottom: 2rem !important;
}

.tutCardContainer-sm {
  width: unset;
  max-height: 80%;
}

.width-cal {
  width: 80%;
}

.tutCardContainer-header {
  /* box-shadow: 0px 2px 5px 5px rgba(0, 0, 0, 0.6); */
  border-radius: 0.2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2rem;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  border: none;
  /* font-weight: bold; */
  font-size: 1.7rem;
  max-height: 33vh;
  /* width: 95%; */
}
.w-95 {
  width: 95% !important;
}

.bottom-border {
  border-bottom: 0.3rem solid #0032AE;
}

.right-border {
  border-right: 0.5rem solid #f57f28;
}

.tutCardContainer-header div {
  cursor: default;
}

.tutCardContainer-header img {
  /* width: 5rem;
  height: auto; */
  width: auto;
  height: 100%;
}
.noLeftPadding {
  padding-left: 0rem;
}
.ptb-extra {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  border-bottom: 2px solid #d4a847;
}

.tutCardButton {
  min-width: unset;
  border: 0;
  opacity: 0.95;
  transform: scale(0.95);
  transition: 0.15s;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  line-height: unset;
  /* font-weight: 600; */
}

.tutCardButton:hover {
  opacity: 1;
  transform: scale(1);
  /* box-shadow: 0px 4px 11px #ffbc0465; */
}

.coloredHover:hover {
  box-shadow: 0px 4px 11px rgba(255, 255, 255, 0.25);
  color: white;
  background: #ec6208;
}

.tutCardContainer-body {
  width: 100%;
  max-height: calc(60vh - 4.5rem);
  overflow-y: auto;
  padding: 2rem 1rem;
  /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)
    ),
    radial-gradient(
      123.61% 123.61% at 50% 50%,
      rgba(255, 255, 255, 0.369) 0%,
      rgba(0, 0, 0, 0.41) 92.49%
    ); */
  background-color: #fff;
  /* backdrop-filter: blur(44px); */
}

.tutCardContainer-body-extraPd {
  padding: 0rem 1.5rem;
}

.tutCardContainer-bodyItem {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 1rem;
  font-size: 1.15rem;
  padding: 0.5rem;
}

.tutCardContainer-bodyItem img {
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
  transition: 0.15s;
  transform: scale(0.95);
  width: 5rem;
}

/* .tutCardContainer-bodyItem img:hover {
  transform: scale(1);
  box-shadow: 0px 4px 44px #0032AE;
} */

.tutCardContainer-bodyItem h2 {
  color: #0032AE;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.25rem;
}

.botm-brdr {
  border-bottom: 6px solid #008A51;
}

.bodyItem-text {
  margin-left: 0.5rem;
  cursor: default;
  max-width: 300px;
}

.tutCardContainer-body-textContainer {
  text-align: left;
  font-size: 1.3rem;
  /* color: rgba(0, 0, 0, 0.6); */
  /* padding: 1rem; */
}

.top-tut-para {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.top-tut-main-heading {
  /* font-family: PassionOne, OpenSans_SemiCondensed !important; */
}

.tutCardContainer-body-textContainer h3 {
  color: #0032AE;
  text-align: initial;
  font-weight: bold;
}

.selfAlign {
  align-self: center;
}

@media only screen and (min-width: 991px) {
  .width-cal {
    width: 28%;
  }
  .tutCardContainer-sm {
    width: unset;
  }
}
.disappear {
  animation: disappear 0.25s forwards;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.flipShell {
  /* padding: 0.5rem; */
  /* background: linear-gradient(0deg, #100b08, #100b08), #c4c4c4; */
  /* box-shadow: inset 0px 0px 28px 10px rgba(215, 124, 17, 0.25); */
  /* border-radius: 0.5rem; */
}

.flipBorder {
  /* border-radius: 0.5rem; */
  /* border: 2px solid #ffd600; */
}
.clap {
  position: relative;
}
.lower__hand {
  color: #fff;
  transform: rotate(20deg);
  position: relative;
  z-index: 1;
}
.upper__hand {
  color: #fff;
  position: absolute;
  z-index: 11;
  left: 0;
  bottom: 0;
  transform: rotate(90deg);
}
.chat__container {
  position: fixed;
  bottom: 0%;
  left: 0%;
  height: 70vh;
  width: 30%;
  z-index: 16;
}
.message__box {
  padding-bottom: 5rem;
}
@media only screen and (max-width: 1100px) {
  .chat__container {
    width: 100%;
    height: 84vh;
    /* bottom: 0%; */
    top: 0%;
    /* bottom: auto; */
  }
}
.cross {
  position: absolute;
  top: 1%;
  left: 30%;
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111;
  cursor: pointer;
}
.line {
  height: 2px;
  width: 30px;
  background-color: black;
  position: relative;
}
.line::before {
  content: "";
  height: 2px;
  width: 30px;
  background-color: #000;
  position: absolute;
  top: 0;
  transform: rotate(90deg);
}
.loding__container {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 991px) {
  .lobbyTutContainer {
    align-items: flex-start;
  }

  .width-cal {
    width: 60%;
    margin-right: 7rem;
    min-height: 70%;
    margin-top: 2rem;
  }

  .tutCardContainer-body {
    /* max-height: 70vh; */
  }

  .tutCardContainer-sm {
    margin-right: 7rem;
    margin-top: 2rem;
  }
}
