.breakout__scene {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: url(./Lobby.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.tint__overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* background: #fff; */
  backdrop-filter: blur(0.5rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.breakout__popup {
  width: 30vw;
  max-height: 70vh;
}
.breakout__popup p {
  color: #000000;
}
.breakout__header {
  width: 30vw;
  height: calc(30vw / 7.22);
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 2rem;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(150, 150, 150, 0.5) 100%
    );
  backdrop-filter: blur(34px);
  /* background-color: #0d2ea6; */
}
.breakout__header .bg_img {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.rooms_heading {
  color: #fff;
  font-size: 1.7rem;
}
.close_img {
  width: 1.7rem;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.close_img:hover {
  transform: scale(1.07);
}
.breakout__options {
  padding: 2rem 2rem 3rem 2rem;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    radial-gradient(
      123.61% 123.61% at 50% 50%,
      rgba(255, 255, 255, 0.369) 0%,
      rgba(0, 0, 0, 0.41) 92.49%
    );
  backdrop-filter: blur(44px);
  /* background-color: #000; */
  max-height: calc(70vh - (30vw / 7.22));
  overflow-y: auto;
}
.single__room {
  display: flex;
  margin-bottom: 1.25rem;
}
.name__desc {
  flex: 0.8;
  padding-right: 0.8rem;
}
.connect__btn {
  flex: 0.2;
  display: flex;
  align-items: center;
}
.name__desc h1 {
  color: #000000;
  font-weight: bold;
}
.connect__btn button {
  background-color: #000000;
  color: #fff;
  border: 0;
  outline: 0;
  padding: 0.8rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.2s ease-in-out;
  transform: scale(0.9);
}
.connect__btn button:hover {
  transform: scale(1);
}
@media (max-width: 991px) {
  .breakout__popup {
    width: 80vw;
  }
  .breakout__header {
    width: 80vw;
    height: calc(80vw / 7.22);
  }
  .tint__overlay {
    align-items: flex-start;
  }
}
.breakout__options::-webkit-scrollbar {
  width: 0.5rem;
}

.breakout__options::-webkit-scrollbar-track {
  background-color: #fff;
}

.breakout__options::-webkit-scrollbar-thumb {
  background-color: #d4a847;
}
